import * as _ from "lodash"
import * as React from "react"
import CashierDialog from "./CashierDialog"
import CashierSelectionModeConfiguration from "./CashierSelectionModeConfiguration"
import { ascendingByCashierFullName } from "./helpers"
import {
    Button,
    Col,
    Grid,
    Image,
    Card
} from "../../wrappers"
import { Form, Row } from "react-bootstrap"
import { Cashier } from "../../../models/Cashier"
import {
    CashierSelection,
    CashierSelectionMode
} from "../../../models/CashierSelection"
import { PageState } from "../../PageState"
import { currentDatabaseRef } from "../../../config/constants"
import { Role } from "../../../config/role"
import { StripedTable } from "../../StripedTable"
import { ToggleButton } from "../../ToggleButton"
import { CashierRole } from "../Configuration/CashierRoles"
import { L10nString } from "../../../helpers/L10n"
import { withShop } from "../../../routes"
import { child, get, off, onValue, remove, set } from "firebase/database"

interface CashierListProps {
    role: Role
    shop: string
}

interface CashierListState {
    showCashier: boolean
    loaded: boolean
    cashiers: Cashier[]
    cashier: Cashier | null
    cashierSelection: CashierSelection
    defaultCashierSelection: CashierSelection
    cashierRoles: CashierRole[]
}

class CashierList extends React.Component<CashierListProps, CashierListState> {

    private get shopKey(): string {
        return this.props.shop
    }

    private get accountKey(): string {
        return this.props.role.account_id
    }

    private get cashiersPath(): string {
        return `v1/accounts/${this.accountKey}/shops/${this.shopKey}/cashiers`
    }

    private cashierPath(cashier: Cashier): string {
        return `${this.cashiersPath}/${cashier.id}`
    }

    private get cashierSelectionPath(): string {
        return `v1/accounts/${this.accountKey}/shops/${this.shopKey}/configuration/cashier_selection`
    }
    private get cashierRolesPath(): string {
        return `v1/accounts/${this.accountKey}/inventory/cashier_roles`
    }

    constructor(props: CashierListProps) {
        super(props)

        this.state = ({
            cashiers: [],
            cashier: null,
            showCashier: false,
            loaded: false,
            cashierSelection: new CashierSelection(),
            defaultCashierSelection: CashierSelection.default,
            cashierRoles: []
        })
    }

    async componentDidMount() {
        this.setState({ cashiers: [], loaded: false })

        this.setupObservers()

        const account = this.props.role.account_id

        const accountConfigRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/pos/cashier_selection`)
        const shopConfigRef = child(currentDatabaseRef(), this.cashierSelectionPath)
        const promises = [get(accountConfigRef), get(shopConfigRef)]
        const [accountConfigSnap, shopConfigSnap] = await Promise.all(promises)

        if (accountConfigSnap && accountConfigSnap.exists()) {
            const cashierSelection = CashierSelection.fromJSON(accountConfigSnap.val())
            this.setState({ defaultCashierSelection: cashierSelection })
        }

        const cashierSelection = CashierSelection.fromJSON(shopConfigSnap.val() ?? {})
        this.setState({ cashierSelection: cashierSelection, loaded: true })
    }

    componentWillUnmount() {
        this.teardownObservers()
    }

    setupObservers() {

        onValue(child(currentDatabaseRef(), this.cashierRolesPath), snapshot => {
            if (!snapshot || !snapshot.exists()) {
                this.setState({ loaded: true })
                return
            }

            let roles: CashierRole[] = []
            if (snapshot && snapshot.exists()) {
                const rolesDict = snapshot.val()
                for (const key in rolesDict) {
                    const roleJson = rolesDict[key]
                    if (roleJson.id && roleJson.name) {
                        roles.push({
                            id: roleJson.id,
                            name: new L10nString(roleJson.name),
                            functionalityBlocklist: roleJson.functionality_blocklist ?? []
                        })
                    }
                }
            }
            if (roles.find(role => {
                return role.id === "_"
            }) === undefined) {
                const newRoles: CashierRole[] = [{
                    id: "_",
                    name: new L10nString("Default"),
                    functionalityBlocklist: []
                }]
                roles = newRoles.concat(roles)
            }

            this.setState({ cashierRoles: roles, loaded: true })
        })

        onValue(child(currentDatabaseRef(), this.cashiersPath), snapshot => {
            if (!snapshot || !snapshot.exists()) {
                return
            }

            const cashiersMap = snapshot.val()

            let cashiers: Cashier[] = []
            for (const key in cashiersMap) {
                const cashier = Cashier.fromJSON(cashiersMap[key])
                if (!_.isNull(cashier)) {
                    cashiers.push(cashier)
                }
            }

            cashiers = cashiers.sort(ascendingByCashierFullName)

            this.setState({ cashiers: cashiers })
        })
    }

    teardownObservers() {
        off(child(currentDatabaseRef(), this.cashierSelectionPath))
        off(child(currentDatabaseRef(), this.cashiersPath))
        off(child(currentDatabaseRef(), this.cashierRolesPath))
    }

    // Actions

    editCashier(cashier: Cashier | null) {
        this.setState({
            cashier: cashier,
            showCashier: true
        })
    }

    async deleteCashier(cashier: Cashier) {
        if (this.state.cashiers.length === 1) {
            alert("A shop must always have minimum 1 cashier")
            return
        }

        const reply = window.confirm(`Really delete "${cashier.fullName ? cashier.fullName : cashier.displayName}" ?`)
        if (reply === true) {
            const cashierRef = child(currentDatabaseRef(), this.cashierPath(cashier))

            await set(cashierRef, null)
        }
    }

    async resetPIN(cashier: Cashier) {
        const cashierPINCodeRef = child(currentDatabaseRef(), `${this.cashierPath(cashier)}/pin_code`)

        await remove(cashierPINCodeRef)
    }

    async toggleUsePINCode(usePINCode?: boolean) {
        const cashierSelection = new CashierSelection(usePINCode, this.state.cashierSelection.selectionPoint, this.state.cashierSelection.pinCodeResetPeriodMonths)

        await this.persistCashierSelection(cashierSelection)

        this.setState({ cashierSelection })
    }

    async updateSelectionPoint(mode?: CashierSelectionMode) {
        const cashierSelection = new CashierSelection(this.state.cashierSelection.usePINCode, mode, this.state.cashierSelection.pinCodeResetPeriodMonths)

        await this.persistCashierSelection(cashierSelection)

        this.setState({ cashierSelection })
    }

    async updateUsePINCodeResetPeriod(period?: number) {
        const cashierSelection = new CashierSelection(this.state.cashierSelection.usePINCode, this.state.cashierSelection.selectionPoint, period)

        await this.persistCashierSelection(cashierSelection)

        this.setState({ cashierSelection })
    }


    async persistCashierSelection(cashierSelection: CashierSelection) {
        await set(child(currentDatabaseRef(), this.cashierSelectionPath), cashierSelection.toJSON())
    }

    handleOverridePINCodeValue(override: boolean) {
        if (override) {
            this.toggleUsePINCode(true)
        } else {
            this.toggleUsePINCode(undefined)
        }
    }

    handleOverridePINCodeResetPeriodValue(override: boolean) {
        if (override) {
            this.updateUsePINCodeResetPeriod(3)
        } else {
            this.updateUsePINCodeResetPeriod(undefined)
        }
    }

    // View

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="cashiers">
                <CashierSelectionModeConfiguration
                    key="cashier_configuration"
                    didSelectMode={async (mode) => { await this.updateSelectionPoint(mode) }}
                    cashierSelectionMode={this.state.cashierSelection.selectionPoint}
                    defaultValue={this.state.defaultCashierSelection.selectionPoint ?? CashierSelectionMode.beforeEachSale}
                />

                {/* <Row md={1} className="g-4">
                    <Col> */}
                <Card className="mb-4" key="panel1">
                    <Card.Header>
                        PIN code
                    </Card.Header>
                    <Card.Body>
                        <Grid>
                            <Row>
                                <Col>
                                    <>
                                        <Form.Switch
                                            id="custom-switch"
                                            label={`Override default value (${(this.state.defaultCashierSelection.usePINCode ?? false) ? "Use PIN code" : "Don't use PIN code"})`}
                                            checked={this.state.cashierSelection.usePINCode !== undefined}
                                            onChange={(e) => this.handleOverridePINCodeValue(e.target.checked)}
                                        />
                                        <br />
                                    </>

                                    {!_.isNil(this.state.cashierSelection.usePINCode) && <>
                                        <ToggleButton enabledTitle="Use PIN code" disabledTitle="Don't use PIN code" active={this.state.cashierSelection.usePINCode} performToggle={async () => { await this.toggleUsePINCode(!this.state.cashierSelection.usePINCode) }} />
                                        <br />
                                    </>}
                                    <p>With this enabled the user will need to enter a personalized PIN code when switching cashiers. </p>
                                    <p>If the PIN code is forgotten, it can be reset here and entered anew the next time the cashier is switched to.</p>
                                </Col>
                            </Row>
                        </Grid>
                    </Card.Body>
                </Card>

                {(this.state.cashierSelection.usePINCode ?? this.state.defaultCashierSelection.usePINCode ?? false) && <>
                    <Card className="mb-4" key="panel2">
                        <Card.Header>
                            PIN code reset period
                        </Card.Header>
                        <Card.Body>
                            <Grid>
                                <Row>
                                    <Col>
                                        <>
                                            <Form.Switch
                                                id="custom-switch"
                                                label={`Override default value (${this.state.defaultCashierSelection.pinCodeResetPeriodMonths ? `${this.state.defaultCashierSelection.pinCodeResetPeriodMonths} months` : `Don't require PIN code reset`})`}
                                                checked={this.state.cashierSelection.pinCodeResetPeriodMonths !== undefined}
                                                onChange={(e) => this.handleOverridePINCodeResetPeriodValue(e.target.checked)}
                                            />
                                            <br />
                                        </>

                                        {!_.isNil(this.state.cashierSelection.pinCodeResetPeriodMonths) && <>

                                            <Form.Control
                                                type="number"
                                                name="pincoderesetperiod"
                                                min={0}
                                                step={1}
                                                value={this.state.cashierSelection.pinCodeResetPeriodMonths ?? ""}
                                                placeholder={"Enter a PIN code reset period in months"}
                                                onChange={(e: any) => { this.updateUsePINCodeResetPeriod(parseInt(e.target.value)) }}
                                                autoComplete="off"
                                            />

                                            <br />
                                        </>}
                                        <p>Setting a value here, will force cashiers to choose a new PIN code every x months </p>
                                    </Col>
                                </Row>
                            </Grid>
                        </Card.Body>
                    </Card>
                </>}

                <Card className="mb-4" key="panel3">
                    <Card.Header>
                        Cashiers
                    </Card.Header>

                    <Card.Body>
                        <Button variant="success" onClick={() => { this.editCashier(null) }}>Add cashier</Button>
                    </Card.Body>

                    <StripedTable>
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Full name</th>
                                <th>Initials</th>
                                {this.state.cashierSelection.usePINCode ? <th>PIN code</th> : null}
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.cashiers.map(cashier => {
                                    return (
                                        <tr
                                            key={cashier.id}
                                            onClick={(event) => { this.editCashier(cashier); event.stopPropagation() }}
                                        >
                                            <td className="narrow"><div style={{ height: "50px", width: "50px" }}><Image src={cashier.imageURL || undefined} style={{ maxWidth: "100%", maxHeight: "100%" }} /> </div></td>
                                            <td>{cashier.fullName}</td>
                                            <td>{cashier.displayName}</td>
                                            {this.state.cashierSelection.usePINCode ? <td className="narrow"><Button variant="info" disabled={cashier.pinCode === null} onClick={async (event) => { event.stopPropagation(); await this.resetPIN(cashier) }}>Reset</Button></td> : null}
                                            <td className="narrow"><Button variant="danger" onClick={async (event) => { event.stopPropagation(); await this.deleteCashier(cashier) }}>X</Button></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </StripedTable>

                </Card>
                {/* </Col>
                </Row> */}
                {
                    this.state.showCashier ? (
                        <CashierDialog
                            key="dialog"
                            account={this.props.role.account_id}
                            shop={this.props.shop}
                            cashier={this.state.cashier}
                            cashierRoles={this.state.cashierRoles}
                            onDone={() => { this.setState({ showCashier: false }) }}
                        />
                    ) : null
                }
            </PageState>
        )
    }
}

export default withShop(CashierList)
