import * as React from "react"
import ExchangeRateMarginConfiguration from "./Configuration/ExchangeRateMarginConfiguration"
import GiftcardTaxationConfiguration from "./Configuration/GiftcardTaxationConfiguration"
import ImageDisplayStyleConfiguration from "./Configuration/ImageDisplayStyleConfiguration"
import OpenRegisterModeConfiguration from "./Configuration/OpenRegisterModeConfiguration"
import PaymentTypesConfiguration from "./Configuration/PaymentTypesConfiguration"
import ReceiptBarcodeConfiguration from "./Configuration/ReceiptBarcodeConfiguration"
import MarkdownConfiguration from "./Configuration/MarkdownConfiguration"
import ShowCameraScanConfiguration from "./Configuration/ShowCameraScanConfiguration"
import { EcommerceConfiguration } from "./Configuration/EcommerceConfiguration"
import { Role } from "../../config/role"
import StockRemovalReasonCodeList from "./Configuration/StockRemovalReasonCodeList"
import { currentDatabaseRef } from "../../config/constants"
import { withRole } from "../../routes"
import { child, get } from "firebase/database"
import CashierSelectionModeConfiguration from "./Configuration/CashierSelectionModeConfiguration"
import SharedConfigurationEdit from "./ShopConfiguration/SharedConfigurationEdit"
import OrderHandlingConfiguration from "./ShopConfiguration/OrderHandlingConfiguration"
import ContributionRatioConfiguration from "./ShopConfiguration/ContributionRatioConfiguration"
import { Card } from "react-bootstrap"

interface ConfigurationProps {
    role: Role

}

interface ConfiguartionState {
    showStock: boolean
    ordersModuleEnabled: boolean
}

class Configuration extends React.Component<ConfigurationProps, ConfiguartionState> {
    constructor(props: ConfigurationProps) {
        super(props)
        this.state = {
            showStock: false,
            ordersModuleEnabled: false
        }
    }
    stockModuleEnabledRef() {
        const account = this.props.role.account_id
        const stockModuleRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/modules/stock/enabled`)
        return stockModuleRef
    }

    async componentDidMount() {
        const stockModuleEnabledSnap = await get(this.stockModuleEnabledRef())
        const ordersEnabledSnap = await get(this.ordersEnabledRef(this.props.role.account_id))
        this.setState({
            showStock: stockModuleEnabledSnap.val() === true,
            ordersModuleEnabled: ordersEnabledSnap.val() === true
        })
    }

    ordersEnabledRef(accountId: string) {
        return child(currentDatabaseRef(), `v1/accounts/${accountId}/configuration/modules/orders/enabled`)
    }

    render() {
        return (
            <div>

                <PaymentTypesConfiguration
                    key="payment_types"
                    role={this.props.role}
                />
                <ContributionRatioConfiguration
                    key="contribution_ratio"
                    role={this.props.role}
                    mode="account"
                />
                <ImageDisplayStyleConfiguration
                    key="image_display_style_configuration"
                    role={this.props.role}
                />
                <ReceiptBarcodeConfiguration
                    key="barcode_type"
                    role={this.props.role}
                />
                <ExchangeRateMarginConfiguration
                    key="exchange_rate_margin"
                    role={this.props.role}
                />
                <OpenRegisterModeConfiguration
                    key="open_register_mode_configuration"
                    role={this.props.role}
                />
                <GiftcardTaxationConfiguration
                    key="giftcard_taxation_configuration"
                    role={this.props.role}
                />
                <EcommerceConfiguration
                    key="ecommerce_configuration"
                    role={this.props.role}
                />
                {this.state.showStock ? (
                    <StockRemovalReasonCodeList
                        key="stock_removal_reason_code"
                        role={this.props.role}
                    />
                ) : null}
                <MarkdownConfiguration
                    key="markdown_configuration"
                    role={this.props.role}
                />

                <ShowCameraScanConfiguration
                    key="show_camera_scan_configuration"
                    role={this.props.role}
                />

                <SharedConfigurationEdit
                    role={this.props.role}
                    path={"maximum_stock_displayed"}
                    name={"maximum stock displayed"}
                    defaultValue={99}
                    description={<><p>By default, Ka-ching POS only shows the actual stock value up to 99. After that it shows 99+.</p><p>You can override this behavior by setting the maximum stock displayed to a different value.<br /></p></>}
                    typeValue="number"
                    minValue={1}
                    mode="account"
                />

                {/** ignore online stock availability */}
                <SharedConfigurationEdit
                    role={this.props.role}
                    path={"ignore_online_stock_availability"}
                    name={"ignore online stock availability"}
                    defaultValue={false}
                    description={<><p>By default, POS will not allow online sales if the stock count for the remote stock location is 0</p><p>You can override this behavior by ignoring the online stock availability.</p></>}
                    typeValue="boolean"
                    mode="account"
                />
                {/** show item in basket */}
                <SharedConfigurationEdit
                    role={this.props.role}
                    path={"show_item_price_in_basket"}
                    name={"show item price in basket"}
                    defaultValue={false}
                    description={<><p>Enabling this will show the final discounted price <i>per item</i> in the basket.</p></>}
                    typeValue="boolean"
                    mode="account"
                />

                {this.state.ordersModuleEnabled ?
                    (
                        <OrderHandlingConfiguration
                            role={this.props.role}
                            mode="account"
                        />
                    )
                    : null}

                <Card className="mb-4" key="panel2">
                    <Card.Header>
                        Cashier selection settings
                    </Card.Header>
                    <CashierSelectionModeConfiguration
                        key="cashier_configuration"
                        role={this.props.role}
                    />

                    <hr className="mb-0 mt-0" />

                    <SharedConfigurationEdit
                        role={this.props.role}
                        name={"PIN code"}
                        defaultValue={false}
                        description={"With this enabled the user will need to enter a personalized PIN code when switching cashiers."}
                        path={"cashier_selection/use_pin_code"}
                        typeValue="boolean"
                        mode="account"
                        shownInCard={true}
                    />

                    <hr className="mb-0 mt-0" />

                    <SharedConfigurationEdit
                        role={this.props.role}
                        path={"cashier_selection/pin_code_reset_period_months"}
                        name={"PIN code reset period (in months)"}
                        defaultValue={undefined}
                        description={`Setting a value here, will force cashiers to choose a new PIN code every x months`}
                        typeValue="number"
                        minValue={1}
                        mode="account"
                        shownInCard={true}
                    />
                </Card>

            </div>
        )
    }
}

export default withRole(Configuration)
