import * as React from "react"
import { Card, Grid, Row, Col, FormControl } from "../../wrappers"
import { CashierSelectionMode } from "../../../models/CashierSelection"
import { Form } from "react-bootstrap"
import * as _ from "lodash"

interface CashierSelectionModeConfigurationProps {
    didSelectMode: (mode?: CashierSelectionMode) => void
    cashierSelectionMode?: CashierSelectionMode
    defaultValue: CashierSelectionMode
}

export default class CashierSelectionModeConfiguration extends React.Component<CashierSelectionModeConfigurationProps> {
    constructor(props: CashierSelectionModeConfigurationProps) {
        super(props)
    }

    default() {
        const d = this.props.defaultValue
        switch (d) {
            case CashierSelectionMode.beforeEachSale: {
                return "Re-select before each sale"
            }
            case CashierSelectionMode.manual: {
                return "Manual cashier selection"
            }
        }
    }


    handleSwitchChange(override: boolean) { 
        if (override) {
            this.props.didSelectMode(CashierSelectionMode.manual)
        } else {
            this.props.didSelectMode(undefined)
        }
    }

    render() {
        return (
            <Card className="mb-4" key="panel1">
                <Card.Header>
                    Cashier selection mode
                </Card.Header>
                <Card.Body>
                    <Grid>
                        <Row>
                            <Col xs={12} sm={8} md={8}>
                                <p>
                                    <>
                                        <Form.Switch
                                            id="custom-switch"
                                            label={`Override default value (${this.default()})`}
                                            checked={this.props.cashierSelectionMode !== undefined}
                                            onChange={(e) => this.handleSwitchChange(e.target.checked)}
                                        />
                                        <br />
                                    </>

                                    {this.props.cashierSelectionMode && <>
                                        <FormControl
                                            as="select"
                                            name="cashier_selection_mode"
                                            placeholder="Select mode"
                                            value={this.props.cashierSelectionMode}
                                            onChange={this.handleInputChange}
                                        >
                                            <option value={CashierSelectionMode.manual}>Manual cashier selection</option>
                                            <option value={CashierSelectionMode.beforeEachSale}>Re-select before each sale</option>
                                        </FormControl>
                                    </>}
                                </p>
                                <p><b>Manual cashier selection</b> allows the users of the POS to manually switch between cashiers.</p>
                                <p><b>Re-select before each sale</b> forces the users of the POS to select a cashier before each sale.</p>
                            </Col>
                        </Row>
                    </Grid>
                </Card.Body>
            </Card>
        )
    }

    handleInputChange = async (event: any) => {
        const newValue = event.target.value

        this.props.didSelectMode(newValue)
    }
}
