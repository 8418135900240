import * as _ from "lodash"

export enum CashierSelectionMode {
    manual = "manual",
    beforeEachSale = "before_each_sale",
}

export class CashierSelection {

    static fromJSON(json: any): CashierSelection {
        if (_.isNil(json)) {
            return new CashierSelection()
        }

        return new CashierSelection(json.use_pin_code, json.selection_point, json.pin_code_reset_period_months)
    }

    static get default(): CashierSelection {
        return new CashierSelection(false, CashierSelectionMode.beforeEachSale, undefined)
    }

    usePINCode?: boolean
    selectionPoint?: CashierSelectionMode
    pinCodeResetPeriodMonths?: number

    constructor(usePINCode?: boolean, selectionPoint?: CashierSelectionMode, pinCodeResetPeriodMonths?: number) {
        this.usePINCode = usePINCode
        this.selectionPoint = selectionPoint
        this.pinCodeResetPeriodMonths = pinCodeResetPeriodMonths
    }

    toJSON(): any {
        const json: any = {}
        if (!_.isNil(this.usePINCode)) {
            json.use_pin_code = this.usePINCode
        }
        if (!_.isNil(this.selectionPoint)) {
            json.selection_point = this.selectionPoint
        }
        if (!_.isNil(this.pinCodeResetPeriodMonths) && !isNaN(this.pinCodeResetPeriodMonths)) {
            json.pin_code_reset_period_months = this.pinCodeResetPeriodMonths
        }
        return json
    }
}